export default {
  title: "Attachments",
  add_attachment: "Add Attachment",
  upload_file: "Upload a file",
  drag_drop: "or drag and drop",
  file_types: "PNG, JPG, GIF up to 10MB",
  transport_docs: "Transport docs",
  delivery_notes: "Delivery Notes",
  shipping_labels: "Shipping Labels",
  pod: "POD",
  additional_attachment: "Additional Attachments",
};
