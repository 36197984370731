import areq from "@/common/helpers/aReq";
import AreqTools from "@letsgoi/areq";
import { USERS, USERS_PROFILE } from "./paths";

const { GET, jsonData, setPassport, setLetgoiHeaders } = AreqTools;

export function reqGetUsersRawList() {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-users"), []))
    .then(GET(`${USERS}?raw=true`))
    .then(jsonData<IdName[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetUserProfile() {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(true, {}))
    .then(GET(USERS_PROFILE))
    .then(jsonData<Auth.Profile>())
    .catch((error) => Promise.reject(error));
}
