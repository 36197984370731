export default {
  title: "Incidences",
  button_add: "Add Incidence",
  title_form: "Add Incidence",
  label_reason: "Specify a reason",
  label_liable: "Attributable to",
  label_assigned_to: "Assigned to",
  label_priority: "Priority",
  label_cost: "Extra cost",
  label_description: "Description",
  label_tags: "Tags",
  label_in_progress: "Set incidence as In Progress",
  placeholder_input: "Choose from the list",
  placeholder_textarea: "Describe in brief",
  placeholder_search_tags: "Type to add o choose previous",
  title_placeholder_empty: "No incidences reported",
  subtitle_placeholder_empty: "Add incidences if necessary",
};
