import { createI18n, type I18nOptions } from "vue-i18n";
import en from "./lang/en";

const options: I18nOptions = {
  locale: "en",
  fallbackLocale: "en",
  messages: { en },
  datetimeFormats: {
    en: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "short",
        hour: "numeric",
        minute: "numeric",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      },
    },
  },
  silentTranslationWarn: true,
};

const i18n = createI18n<false, typeof options>(options);
export default i18n;
