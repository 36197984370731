export default {
  list: {
    add_orders_btn_cancel: "Cancel",
    add_orders_btn_add: "Add",
  },
  detail: {
    add_orders_btn_cancel: "Cancel",
    add_orders_btn_add: "Add",
    orders: {
      title: "Orders added to this project",
      link_add_order: "Add Order",
      link_view_orders: "View all orders",
    },
  },
  creation: {
    title_section_information: "Linehaul information",
    title_section_dates: "Linehaul dates",
    title_section_additional_info: "Linehaul additional info",
    title_section_observations: "Linehaul observations",
    label_input_type: "Linehaul type",
    placeholder_type: "Select type",
    label_input_transport: "Transport provider",
    placeholder_transport: "Select provider",
    label_input_origin: "Origin provider",
    placeholder_origin: "Select origin provider",
    label_input_destination: "Destination provider",
    placeholder_destination: "Select destination provider",
    label_input_date_start: "Linehaul starts",
    placeholder_date_start: "Linehaul starts",
    label_input_date_end: "Linehaul ends",
    placeholder_date_end: "Linehaul ends",
    label_input_cost: "Linehaul cost",
    placeholder_cost: "Insert cost",
    label_input_pallets: "Nº pallets",
    placeholder_pallets: "Insert nº pallets",
    label_input_observations: "Observations",
    placeholder_observations: "Add a new observation",
    creation_linehaul: "Creating linehaul",
    btn_cancel: "Cancel",
    btn_save: "Save",
  },
};
