import i18n from "@/common/helpers/i18n";
export const DOCUMENT_TITLE = {
  default: "Brain",
  login: `${i18n.global.t("app.login")} | Brain`,
  recovery: `${i18n.global.t("app.recovery")} | Brain`,
  notFound: `${i18n.global.t("app.not-found")} | Brain`,
  orders: {
    list: `${i18n.global.t("app.orders")} | Brain`,
    detail: (val) => `${val} | ${i18n.global.t("app.orders")} | Brain`,
    creation: `${i18n.global.t("app.new-order")} | Brain`,
  },
  incidences: {
    list: `${i18n.global.t("app.incidences")} | Brain`,
    detail: (val) => `${val} | ${i18n.global.t("app.incidences")} | Brain`,
    creation: `${i18n.global.t("app.new-incidence")} | Brain`,
  },
  operations: {
    list: `${i18n.global.t("app.operations")} | Brain`,
    detail: (val) => `${val} | ${i18n.global.t("app.operations")} | Brain`,
  },
  teams: {
    list: `${i18n.global.t("app.teams")} | Brain`,
    detail: (val) => `${val} | ${i18n.global.t("app.teams")} | Brain`,
    creation: `${i18n.global.t("app.new-team")} | Brain`,
  },
  providers: {
    list: `${i18n.global.t("app.providers")} | Brain`,
    detail: (val) => `${val} | ${i18n.global.t("app.providers")} | Brain`,
    creation: `${i18n.global.t("app.new-provider")} | Brain`,
  },
  routes: {
    list: (section = `${i18n.global.t("app.routes")}`) => `${section} | Brain`,
    project: (project, section) => `${project} | ${section} | Brain`,
  },
  linehauls: {
    list: `${i18n.global.t("app.linehauls")} | Brain`,
    detail: (val) => `${val} | ${i18n.global.t("app.linehauls")} | Brain`,
    creation: `${i18n.global.t("app.new-linehaul")} | Brain`,
  },
  dedicated: {
    list: `${i18n.global.t("app.dedicated")} | Brain`,
    calendar: `${i18n.global.t("app.dedicated-calendar")} | Brain`,
    detail: (val) => `${val} | ${i18n.global.t("app.dedicated")} | Brain`,
    creation: `${i18n.global.t("app.new-dedicated")} | Brain`,
  },
  Attachments: {
    detail: (val) => `${val} | ${i18n.global.t("app.Attachments")} | Brain`,
  },
  alerts: {
    list: `${i18n.global.t("alerts.module-name")} | Brain`,
    detail: (val) => `${val} | ${i18n.global.t("alerts.module-name")} | Brain`,
    creation: `${i18n.global.t("alerts.create-alert")} | Brain`,
  },
  clients: {
    list: `${i18n.global.t("clients.module-name")} | Brain`,
    detail: (val) => `${val} | ${i18n.global.t("clients.module-name")} | Brain`,
  },
  articles: {
    list: `${i18n.global.t("clients.articles")} | Brain`,
    detail: (val) => `${val} | ${i18n.global.t("clients.articles")} | Brain`,
    creation: `${i18n.global.t("clients.create-article")} | Brain`,
  },
};

export const setDocumentTitle = (title) => (document.title = title);
