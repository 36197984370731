<template>
  <div>
    <!-- ORDERS -->
    <ToolTip
      v-if="
        passport(
          find(menu, { reference: 'orders' })?.permissions ?? EMPTY_STRING
        )
      "
      tip="Orders"
      class="custom-class rounded p-1 hover:bg-sky-600"
      :style="{
        '--var-bg-hover': bgHover,
        '--var-bg-selected': bgSelected,
        '--var-bg': sidebarColor,
      }"
    >
      <router-link class="h-5 w-5" :to="getUrlItemMenu('orders')">
        <ClipboardIcon class="h-5 w-5 text-white" />
      </router-link>
    </ToolTip>

    <!-- 'INCIDENCES' -->
    <ToolTip
      v-if="
        passport(
          find(menu, { reference: 'incidences' })?.permissions ?? EMPTY_STRING
        )
      "
      tip="Incidences"
      class="custom-class rounded p-1 hover:bg-sky-600"
      :style="{
        '--var-bg-hover': bgHover,
        '--var-bg-selected': bgSelected,
        '--var-bg': sidebarColor,
      }"
    >
      <router-link class="h-5 w-5" :to="getUrlItemMenu('incidences')">
        <ExclamationTriangleIcon class="h-5 w-5 text-white" />
      </router-link>
    </ToolTip>

    <!-- 'ALERTS' -->
    <ToolTip
      v-if="
        (find(menu, { reference: 'alerts' })?.permissions
          ? passport(
              find(menu, { reference: 'alerts' })?.permissions ?? EMPTY_STRING
            )
          : true) &&
        (find(menu, { reference: 'alerts' })?.scope
          ? passportScope(find(menu, { reference: 'alerts' })?.scope)
          : true)
      "
      tip="Alerts"
      class="custom-class rounded p-1 hover:bg-sky-600"
      :style="{
        '--var-bg-hover': bgHover,
        '--var-bg-selected': bgSelected,
        '--var-bg': sidebarColor,
      }"
    >
      <router-link class="h-5 w-5" :to="getUrlItemMenu('alerts')">
        <BellAlertIcon class="h-5 w-5 text-white" />
      </router-link>
    </ToolTip>

    <!-- SHORCUTS -->
    <ToolTip
      v-if="
        passport(
          find(menu, { reference: 'shortcuts' })?.permissions ?? EMPTY_STRING
        )
      "
      tip="Shortcuts"
      class="custom-class rounded p-1 hover:bg-sky-600"
      :class="{
        hidden: !shortcuts?.length > 0,
      }"
      :style="{
        '--var-bg-hover': bgHover,
        '--var-bg-selected': bgSelected,
        '--var-bg': sidebarColor,
      }"
    >
      <button
        class="h-5 w-5 disabled:cursor-not-allowed"
        @click="$emit('expand:menu')"
      >
        <BoltIcon class="h-5 w-5 text-white" />
      </button>
    </ToolTip>

    <!-- CLIENTS -->
    <ToolTip
      v-if="
        passport(
          find(menu, { reference: 'clients' })?.permissions ?? EMPTY_STRING
        )
      "
      tip="Clients"
      class="custom-class rounded p-1 hover:bg-sky-600"
      :style="{
        '--var-bg-hover': bgHover,
        '--var-bg-selected': bgSelected,
        '--var-bg': sidebarColor,
      }"
    >
      <router-link class="h-5 w-5" :to="getUrlItemMenu('clients')">
        <FolderOpenIcon class="h-5 w-5 text-white" />
      </router-link>
    </ToolTip>

    <!-- OPERATIONS -->
    <ToolTip
      v-if="
        passport(
          find(menu, { reference: 'operations' })?.permissions ?? EMPTY_STRING
        )
      "
      tip="Operations"
      class="custom-class rounded p-1 hover:bg-sky-600"
      :style="{
        '--var-bg-hover': bgHover,
        '--var-bg-selected': bgSelected,
        '--var-bg': sidebarColor,
      }"
    >
      <router-link class="h-5 w-5" :to="getUrlItemMenu('operations')">
        <BriefcaseIcon class="h-5 w-5 text-white" />
      </router-link>
    </ToolTip>

    <!-- TEAMS -->
    <ToolTip
      v-if="
        passport(
          find(menu, { reference: 'teams' })?.permissions ?? EMPTY_STRING
        )
      "
      tip="Teams"
      class="custom-class rounded p-1 hover:bg-sky-600"
      :style="{
        '--var-bg-hover': bgHover,
        '--var-bg-selected': bgSelected,
        '--var-bg': sidebarColor,
      }"
    >
      <router-link class="h-5 w-5" :to="getUrlItemMenu('teams')">
        <UserGroupIcon class="h-5 w-5 text-white" />
      </router-link>
    </ToolTip>

    <!-- PROVIDERS -->
    <ToolTip
      v-if="
        passport(
          find(menu, { reference: 'providers' })?.permissions ?? EMPTY_STRING
        )
      "
      tip="Providers"
      class="custom-class rounded p-1 hover:bg-sky-600"
      :style="{
        '--var-bg-hover': bgHover,
        '--var-bg-selected': bgSelected,
        '--var-bg': sidebarColor,
      }"
    >
      <router-link class="h-5 w-5" :to="getUrlItemMenu('providers')">
        <IdentificationIcon class="h-5 w-5 text-white" />
      </router-link>
    </ToolTip>

    <!-- LAST MILE -->
    <ToolTip
      v-if="
        passport(
          find(menu, { reference: 'last-mile' })?.permissions ?? EMPTY_STRING
        )
      "
      tip="Last mile"
      class="custom-class rounded p-1 hover:bg-sky-600"
      :style="{
        '--var-bg-hover': bgHover,
        '--var-bg-selected': bgSelected,
        '--var-bg': sidebarColor,
      }"
    >
      <router-link class="h-5 w-5" :to="getUrlItemMenu('last-mile')">
        <TruckIcon class="h-5 w-5 text-white" />
      </router-link>
    </ToolTip>

    <!-- LINEHAULS -->
    <ToolTip
      v-if="
        passport(
          find(menu, { reference: 'linehauls' })?.permissions ?? EMPTY_STRING
        )
      "
      tip="Linehauls"
      class="custom-class rounded p-1 hover:bg-sky-600"
      :style="{
        '--var-bg-hover': bgHover,
        '--var-bg-selected': bgSelected,
        '--var-bg': sidebarColor,
      }"
    >
      <router-link class="h-5 w-5" :to="getUrlItemMenu('linehauls')">
        <MapIcon class="h-5 w-5 text-white" />
      </router-link>
    </ToolTip>

    <!-- DEDICATED SERVICES -->
    <ToolTip
      v-if="
        passport(
          find(menu, { reference: 'dedicated-services' })?.permissions ??
            EMPTY_STRING
        )
      "
      tip="Dedicated Services"
      class="custom-class rounded p-1 hover:bg-sky-600"
      :style="{
        '--var-bg-hover': bgHover,
        '--var-bg-selected': bgSelected,
        '--var-bg': sidebarColor,
      }"
    >
      <router-link class="h-5 w-5" :to="getUrlItemMenu('dedicated-services')">
        <SparklesIcon class="h-5 w-5 text-white" />
      </router-link>
    </ToolTip>
  </div>
</template>

<script setup>
import tinycolor from "tinycolor2";
import { FilterFactory } from "@letsgoi/qp";

import {
  ClipboardIcon,
  ExclamationTriangleIcon,
  BoltIcon,
  BriefcaseIcon,
  FolderOpenIcon,
  MapIcon,
  SparklesIcon,
  TruckIcon,
  UserGroupIcon,
  IdentificationIcon,
  BellAlertIcon,
} from "@heroicons/vue/24/outline";
import { computed, inject } from "vue";
import find from "lodash-es/find";
import { EMPTY_STRING } from "@/common/helpers/constants";
import ToolTip from "@/common/components/ToolTip.vue";
import { passportScopeKey } from "@/common/plugins/passport";

const menu = inject("menu", []);
const passport = inject("passport");
const passportScope = inject(passportScopeKey);

const getUrlItemMenu = (nameParent = EMPTY_STRING) => {
  if (!nameParent) return EMPTY_STRING;
  let item = EMPTY_STRING;
  const parent = find(menu.value, {
    reference: nameParent,
  });
  item =
    parent?.children?.length > 0
      ? find(parent?.children, { reference: "overview" })
      : parent;

  const filterFactory = new FilterFactory();
  const query = filterFactory.urlToVueRouterQueryParams(
    item?.search ?? new URL(EMPTY_STRING, globalThis.location.origin)
  );
  return { path: item?.pathname?.pathname, query };
};

const shortcuts = computed(() => {
  const item = find(menu.value, { reference: "shortcuts" });
  return item?.children;
});

const sidebarColor = inject("sidebarColor");

const bgHover = computed(() => {
  const tcolor = tinycolor(sidebarColor.value);
  return tcolor.lighten(10);
});

const bgSelected = computed(() => {
  const tcolor = tinycolor(sidebarColor.value);
  return tcolor.darken(10);
});
</script>

<style scoped>
:root {
  --var-bg-hover: transparent;
  --var-bg-selected: transparent;
  --var-bg: transparent;
}

.custom-class {
  background-color: var(--var-bg);
}

.custom-class:hover {
  background-color: var(--var-bg-hover);
}

.custom-class:focus {
  background-color: var(--var-bg-hover);
}
</style>
