<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 z-50 flex items-end p-2 sm:items-start sm:p-4"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <div
        class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-2xl ring-1 ring-black ring-opacity-5"
      >
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <ArrowPathIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900">
                We've got new version ready !
              </p>
              <p class="mt-1 text-sm text-gray-500">
                Update now to get the last Brain Version
              </p>
              <div class="mt-3 flex space-x-7">
                <div class="flex items-center" v-if="loading">
                  <loader-circle
                    class="mx-2 h-4 w-4 animate-spin fill-blue-600 text-gray-200"
                  />
                  <p class="text-sm font-medium">Updaing..</p>
                </div>
                <button
                  v-else
                  type="button"
                  class="rounded-md bg-white text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  @click="handlerReleaseUpdated"
                >
                  Update now
                </button>
                <button
                  v-if="!loading"
                  type="button"
                  class="invisible rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Dismiss
                </button>
              </div>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button
                type="button"
                @click="handlerReleaseUpdated"
                class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <span class="sr-only">Close</span>
                <XMarkIcon class="invisible h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { XMarkIcon, ArrowPathIcon } from "@heroicons/vue/20/solid";
import { ref } from "vue";
import LoaderCircle from "@/common/components/LoaderCircle.vue";

const loading = ref(false);
const emit = defineEmits<{
  "app:updated": [];
}>();

function handlerReleaseUpdated() {
  loading.value = true;
  const cacheControlElement = document.createElement("meta");
  cacheControlElement.setAttribute("http-equiv", "cache-control");
  cacheControlElement.setAttribute("content", "no-cache");

  const expiresElement = document.createElement("meta");
  expiresElement.setAttribute("http-equiv", "expires");
  expiresElement.setAttribute("content", "0");

  const pragmaElement = document.createElement("meta");
  pragmaElement.setAttribute("http-equiv", "pragma");
  pragmaElement.setAttribute("content", "no-cache");
  document.head.appendChild(cacheControlElement);
  document.head.appendChild(expiresElement);
  document.head.appendChild(pragmaElement);
  setTimeout(() => {
    window.location.reload();
    loading.value = false;
    emit("app:updated");
  }, 400);
}
</script>
