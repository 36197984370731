<template>
  <Info v-if="showInfo" :message="message" @close="close" />
  <Error v-if="showError" :errors="errors" @close="close" />
  <Warning v-if="showWarning" :message="message" @close="close" />
</template>

<script setup lang="ts">
import Info from "./InfoBox.vue";
import Error from "./ErrorBox.vue";
import Warning from "./WarningBox.vue";
import { ref } from "vue";
import type { AlertFunc, ErrorFunc } from "./index";
import { EMPTY_STRING } from "@/common/helpers/constants";

const showInfo = ref<boolean>(false);
const showWarning = ref<boolean>(false);
const showError = ref<boolean>(false);
const message = ref<string>(EMPTY_STRING);
const errors = ref<string[]>([]);
const info: AlertFunc = (newMessage, options = { autoCloseInMs: 0 }) => {
  message.value = newMessage;
  showInfo.value = true;
  if (options?.autoCloseInMs > 0) {
    globalThis.setTimeout(close, options.autoCloseInMs);
  }
};
const error: ErrorFunc = (newErrors, options = { autoCloseInMs: 0 }) => {
  errors.value = newErrors;
  showError.value = true;
  if (options?.autoCloseInMs > 0) {
    globalThis.setTimeout(close, options.autoCloseInMs);
  }
};

const warning: AlertFunc = (newMessage, options = { autoCloseInMs: 0 }) => {
  message.value = newMessage;
  showWarning.value = true;
  if (options?.autoCloseInMs > 0) {
    globalThis.setTimeout(close, options.autoCloseInMs);
  }
};

const close = () => {
  showInfo.value = false;
  showError.value = false;
  showWarning.value = false;
};

defineExpose({ error, info, warning });
</script>
