<template>
  <Draggable
    tag="ul"
    v-model="list"
    :group="group"
    @start="handleStartDrag"
    @end="handleEndDrag"
    :animation="200"
    handle=".handle"
    @change="handlerChange"
    item-key="id"
  >
    <template #item="{ element }">
      <li
        :style="{
          '--var-bg-hover': bgHover,
          '--var-bg-selected': bgSelected,
          '--var-icon-color': iconColor,
          '--var-bg': sidebarColor,
        }"
        :class="[
          element?.current ? 'outline-none ring-2 ring-white' : '',
          'group relative mb-1 grid w-full  grid-cols-5 items-center gap-4 rounded-md py-2 pl-2 pr-2 text-sm font-normal text-white',
        ]"
        @click.stop="
          $emit('navigate:to', { item: sectionMenu, subItem: element })
        "
        @mouseenter="handlerMouseEnter"
        @mouseleave="handlerMouseLeave"
      >
        <button id="iconBars" class="hidden" :style="{ color: iconColor }">
          <Bars4Icon class="handle h-5 w-5 cursor-grab" />
        </button>
        <span
          :title="element?.name"
          class="col-span-3 col-start-2 cursor-pointer truncate text-white"
        >
          {{ element?.name }}
        </span>

        <button
          v-if="passport('delete-shortcuts')"
          id="iconTrash"
          class="hidden"
          :style="{ color: iconColor }"
        >
          <TrashIcon
            class="h-5 w-5 cursor-pointer"
            @click.stop="$emit('open:modal:confirm', element)"
          />
        </button>
      </li>
    </template>
  </Draggable>
</template>

<script setup>
import { Bars4Icon, TrashIcon } from "@heroicons/vue/24/outline";
import Draggable from "vuedraggable";
import { computed, inject, ref, watchEffect } from "vue";
import { reqPatchShortcut } from "@/common/helpers/api";
import useErrorHandler from "@/common/composables/useErrorHandler";
import tinycolor from "tinycolor2";

const props = defineProps(["sectionMenuChildrens", "group", "sectionMenu"]);
const emit = defineEmits([
  "update:menu-childrens",
  "navigate:to",
  "open:modal:confirm",
]);
const drag = ref(false);
const errorHandler = useErrorHandler();
const list = ref([]);
const passport = inject("passport");

watchEffect(() => {
  list.value = props.sectionMenuChildrens;
});

const handlerChange = async (ev) => {
  try {
    await reqPatchShortcut({
      id: ev?.moved?.element?.id,
      sort: ev?.moved?.newIndex,
    });
    emit("update:menu-childrens", { items: list.value });
  } catch (e) {
    await errorHandler?.handleApiExceptions(e);
  }
};

const handleStartDrag = (ev) => {
  drag.value = true;

  ev.from.children?.forEach((liEl) => {
    liEl.classList.remove("bg-sky-400");
    liEl.classList.remove("custom-class");
    liEl.classList.remove("draggabble");

    liEl.children?.forEach((child) => {
      if (["iconBars", "iconTrash"].includes(child.id))
        child.classList.add("hidden");
    });
  });
};

const handleEndDrag = (ev) => {
  drag.value = false;

  ev.to.children?.[ev.newIndex].classList.add("bg-sky-400");
  ev.to.children?.[ev.newIndex].classList.add("custom-class");
  ev.to.children?.[ev.newIndex].classList.add("draggabble");

  ev.to.children?.[ev.newIndex]?.children?.forEach((child) => {
    if (["iconBars", "iconTrash"].includes(child.id))
      child.classList.remove("hidden");
  });
};

const handlerMouseEnter = (ev) => {
  if (drag.value) return;

  ev.target.classList.add("bg-sky-400");
  ev.target.classList.add("custom-class");
  ev.target.classList.add("draggabble");

  ev.target.children?.forEach((child) => {
    if (["iconBars", "iconTrash"].includes(child.id))
      child.classList.remove("hidden");
  });
};

const handlerMouseLeave = (ev) => {
  ev.target.classList.remove("bg-sky-400");
  ev.target.classList.remove("custom-class");
  ev.target.classList.remove("draggabble");

  ev.target.children?.forEach((child) => {
    if (["iconBars", "iconTrash"].includes(child.id))
      child.classList.add("hidden");
  });
};

const sidebarColor = inject("sidebarColor");

const bgHover = computed(() => {
  const tcolor = tinycolor(sidebarColor.value);
  return tcolor.lighten(10);
});

const bgSelected = computed(() => {
  const tcolor = tinycolor(sidebarColor.value);
  return tcolor.darken(10);
});

const iconColor = computed(() => {
  const tcolor = tinycolor(sidebarColor.value);
  return tcolor.darken(40);
});
</script>

<style scoped>
:root {
  --var-bg-hover: transparent;
  --var-bg-selected: transparent;
  --var-color-icon: transparent;
  --var-bg: transparent;
}

.draggabble {
  background-color: var(--var-bg-hover);
  color: var(--var-color-icon);
}

.custom-class {
  background-color: var(--var-bg);
}

.custom-class:hover {
  background-color: var(--var-bg-hover);
}

.custom-class:focus {
  background-color: var(--var-bg-hover);
}
</style>
