export type Channel = "shortcut";

export default class ComponentComunicator {
  private broadcastChannel: BroadcastChannel;

  constructor(channel: Channel) {
    this.broadcastChannel = new BroadcastChannel(channel);
  }

  listenChannel(callback: (event: Event) => void) {
    this.broadcastChannel.addEventListener("message", callback);
  }
  leaveChannel() {
    this.broadcastChannel.close();
  }

  sendToChannel(data: any) {
    this.broadcastChannel.postMessage(data);
  }
}
