import areq from "@/common/helpers/aReq";
import AreqTools from "@letsgoi/areq";
import { SHORTCUTS } from "./paths";
import { EMPTY_STRING } from "@/common/helpers/constants";

const {
  POST,
  GET,
  DELETE,
  PATCH,
  jsonData,
  setBody,
  setPassport,
  setLetgoiHeaders,
} = AreqTools;

export function reqGetShortcuts(queryParams = EMPTY_STRING) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-shortcuts"), []))
    .then(GET(`${SHORTCUTS}${queryParams}`))
    .then(jsonData())
    .catch((error) => Promise.reject(error));
}

export function reqPostShortcut(shortcut) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody(shortcut))
    .then(setPassport(globalThis.passport("create-shortcuts"), {}))
    .then(POST(SHORTCUTS))
    .then(jsonData())
    .catch((error) => Promise.reject(error));
}

export function reqDeleteShortcut(shortcutId) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("delete-shortcuts"), {}))
    .then(DELETE(`${SHORTCUTS}/${shortcutId}`))
    .catch((error) => Promise.reject(error));
}

export function reqPatchShortcut(shortcut) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setBody(shortcut))
    .then(setPassport(globalThis.passport("update-shortcuts"), {}))
    .then(PATCH(`${SHORTCUTS}/${shortcut?.id}`))
    .then(jsonData())
    .catch((error) => Promise.reject(error));
}
