export default {
  title: "Delivery Logs",
  button_add: "Add Status",
  title_form: "Add Status",
  subtitle_form: "Apply the date for the status:",
  button_choose: "Choose a different day",
  title_report: "Report Incidence",
  text_today: "Today, ",
  label_priority: "Priority for the incidence",
  label_type: "Specify the incidence",
  label_description: "Description",
  placeholder_input: "Choose from the list",
  placeholder_textarea: "Describe in brief",
  title_placeholder_empty: "No incidences reported",
  subtitle_placeholder_empty: "Add incidences if necessary",
};
