<template>
  <div
    class="z-20 min-h-full bg-sky-500 md:h-screen"
    :style="{ backgroundColor: sidebarColor }"
  >
    <!-- Expanded sidebar menu: in small screen -->
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-40 xl:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel
              class="relative flex w-full max-w-[12rem] flex-1 flex-col bg-sky-500 pt-5"
              :style="{ backgroundColor: sidebarColor }"
            >
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute right-0 top-0 -mr-12 pt-2">
                  <button
                    type="button"
                    class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <SidebarMenu />
            </DialogPanel>
          </TransitionChild>
          <div class="w-14 flex-shrink-0" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <div
      data-menu="Expanded sidebar menu: in small screen"
      class="relative hidden xl:fixed xl:inset-y-0 xl:flex xl:w-48 xl:flex-col"
      v-show="sidebarStaticOpen"
    >
      <div
        class="h-full bg-sky-500 pt-5"
        :style="{ backgroundColor: sidebarColor }"
      >
        <button
          type="button"
          class="absolute right-3 top-5 z-10 text-neutral-100 hover:text-neutral-50"
          @click.stop="sidebarStaticOpen = false"
        >
          <ChevronDoubleLeftIcon class="h-4 w-4" />
        </button>
        <SidebarMenu class="h-full" />
      </div>

      <div class="w-14 flex-shrink-0" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </div>

    <!-- Collapset Sidebarmenu on response to manual click on back button of sidebar -->
    <div
      data-menu="Collapset Sidebarmenu on response to manual click on back button of sidebar"
    >
      <div
        v-if="sidebarStaticOpen === false"
        class="flex-column align-items-center mt-4 flex h-full flex-shrink-0 bg-sky-500"
        :style="{ backgroundColor: sidebarColor }"
      >
        <button
          type="button"
          class="px-4 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500"
          @click="sidebarStaticOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3CenterLeftIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <CollapsedMenu
          class="flex-column justify-content-center align-items-center mt-24 flex grow-0 gap-4 overflow-y-auto"
          @expand:menu="sidebarStaticOpen = true"
        />
      </div>
    </div>

    <!-- Collapset Sidebarmenu on resposive response to resize of document width -->
    <div
      data-menu="Collapset Sidebarmenu on resposive response to resize of document width"
      :class="[
        'h-[var(--sidebar-menu-height)] md:h-auto',
        { 'xl:pl-48': sidebarStaticOpen },
      ]"
    >
      <div
        class="flex justify-between md:grid md:grid-rows-[min-content_1fr] md:gap-y-8"
      >
        <button
          type="button"
          class="p-4 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 xl:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3CenterLeftIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <CollapsedMenu
          class="flex-column hidden items-center gap-4 overflow-y-auto py-2 md:flex xl:hidden"
          @expand:menu="sidebarOpen = true"
        />
        <div
          class="flex items-center justify-center divide-x px-2 py-4 text-white md:hidden"
        >
          <BrainLogoCropedIcon class="mr-2 h-9 w-9" />
          <p
            class="mr-2 pl-2 text-sm font-medium leading-5"
            :title="authStore.gettProfile?.email"
          >
            {{ authStore.gettProfile?.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CollapsedMenu from "./CollapsedMenu.vue";
import { provide, onMounted, ref, computed } from "vue";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  Bars3CenterLeftIcon,
  XMarkIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/vue/24/outline";
import SidebarMenu from "./SidebarMenu.vue";
import { config } from "./config";
import { reqGetShortcuts } from "@/common/helpers/api";
import useErrorHandler from "@/common/composables/useErrorHandler";
import ComponentComunicator from "@/common/helpers/ComponentComunicator";
import find from "lodash-es/find";
import { useAuthStore } from "@/modules/auth/store";
import { useMagicKeys, whenever } from "@vueuse/core";
import BrainLogoCropedIcon from "@/assets/img/BrainLogoCropedIcon.vue";

const sidebarOpen = ref(false);
const sidebarStaticOpen = ref(true);
const errorHandler = useErrorHandler();
const componentComunicator = new ComponentComunicator("shortcut");
componentComunicator.listenChannel(handlerShortcutsUpdate);
const menu = ref(config);
const keys = useMagicKeys();

const authStore = useAuthStore();

provide("menu", menu);

whenever(keys.Ctrl_shift_1, () => {
  sidebarStaticOpen.value = !sidebarStaticOpen.value;
});
whenever(keys.Meta_shift_1, () => {
  sidebarStaticOpen.value = !sidebarStaticOpen.value;
});

onMounted(async () => {
  try {
    await getShortcuts();
  } catch (exception) {
    await errorHandler.handleApiExceptions(exception);
  }
});

const sidebarColor = computed(() => {
  const { settings } = authStore.gettProfile;
  return settings?.sidebar_setting?.accent_color || "#0EA5E9";
});

provide("sidebarColor", sidebarColor);

async function getShortcuts() {
  const shortcuts = await reqGetShortcuts();
  const menuShortcuts = find(menu.value, { reference: "shortcuts" });
  menuShortcuts.children = shortcuts.map((shortcut) => {
    return {
      ...shortcut,
      pathname: find(menu.value, { reference: shortcut?.type })?.pathname,
      search: new URL(shortcut?.shortcut, globalThis.location.origin),
    };
  });
}
async function handlerShortcutsUpdate() {
  await getShortcuts();
}
</script>
