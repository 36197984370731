<template>
  <svg
    width="64"
    height="15"
    viewBox="0 0 64 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1105_6632)">
      <path
        d="M41.4842 5.40862C41.4842 4.59732 41.3508 3.92124 41.0841 3.24515C40.8173 2.56907 40.4171 2.02821 39.8835 1.48734C39.35 0.946477 38.8164 0.540828 38.1495 0.270395C37.4825 -3.7536e-05 36.8156 -0.135254 36.0153 -0.135254C35.2149 -0.135254 34.548 -3.7536e-05 33.881 0.270395C33.2141 0.540828 32.6805 0.946477 32.147 1.48734C31.6134 2.02821 31.3466 2.56907 30.9465 3.24515C30.8131 3.92124 30.6797 4.73253 30.6797 5.40862V12.7103C30.6797 13.5216 31.3466 14.0625 32.0136 14.0625C32.8139 14.0625 33.3475 13.5216 33.3475 12.7103V10.8173H38.683V12.7103C38.683 13.5216 39.35 14.0625 40.0169 14.0625C40.8173 14.0625 41.3508 13.5216 41.3508 12.7103V5.40862H41.4842ZM33.3475 8.11294V5.40862C33.3475 5.00297 33.4809 4.73253 33.6143 4.32689C33.7476 4.05645 33.881 3.6508 34.1478 3.51559C34.4146 3.24515 34.6814 3.10994 34.9481 2.97472C35.3483 2.83951 35.7485 2.70429 36.1487 2.70429C36.5488 2.70429 36.8156 2.83951 37.2158 2.97472C37.4825 3.10994 37.8827 3.24515 38.0161 3.51559C38.2829 3.78602 38.4163 4.05645 38.5497 4.32689C38.683 4.73253 38.8164 5.13818 38.8164 5.40862V8.11294H33.3475Z"
        fill="white"
      />
      <path
        d="M25.2107 4.05649C25.0773 3.65084 24.9439 3.10998 24.6771 2.70433C24.4104 2.29868 24.1436 1.89303 23.8768 1.6226C23.61 1.35216 23.2099 0.946515 22.8097 0.811298C22.4095 0.540865 22.0094 0.405649 21.4758 0.270433C20.9422 0.135216 20.4087 0 20.0085 0H15.8734C15.2065 0 14.5396 0.540865 14.5396 1.35216V12.7103C14.5396 13.5216 15.2065 14.0625 15.8734 14.0625C16.6738 14.0625 17.2073 13.3864 17.2073 12.7103V10.9525H20.2753L21.3424 13.3864C21.7426 14.0625 22.5429 14.1977 23.2099 13.7921C23.8768 13.3864 24.0102 12.5751 23.61 11.899L22.8097 10.1412C23.2099 9.87079 23.61 9.60036 23.8768 9.19471C24.1436 8.78906 24.4104 8.51863 24.6771 8.11298C24.9439 7.70733 25.0773 7.30168 25.2107 6.89603C25.3441 6.49039 25.3441 5.94952 25.3441 5.54387C25.3441 5.00301 25.3441 4.46214 25.2107 4.05649ZM20.0085 8.2482H17.3407V2.70433H20.0085C20.4087 2.70433 20.6755 2.83954 21.0756 2.97476C21.3424 3.10998 21.7426 3.38041 21.876 3.51562C22.1427 3.78606 22.2761 4.05649 22.4095 4.32692C22.5429 4.73257 22.6763 5.13822 22.6763 5.54387C22.6763 5.94952 22.5429 6.21995 22.4095 6.6256C22.2761 6.89603 22.0094 7.30168 21.876 7.4369C21.6092 7.70733 21.3424 7.84255 21.0756 7.97777C20.6755 8.2482 20.2753 8.2482 20.0085 8.2482Z"
        fill="white"
      />
      <path
        d="M8.13675 7.03125C8.53692 6.6256 8.80369 6.21995 8.93708 5.67909C9.07047 5.13822 9.20386 4.73257 9.20386 4.19171C9.20386 3.65084 9.07047 3.10998 8.93708 2.56911C8.6703 2.02825 8.40353 1.6226 8.00336 1.21695C7.60319 0.811298 7.20302 0.540865 6.66947 0.270433C6.13591 0.135216 5.60235 0 5.06879 0H1.46728C0.666947 0 0 0.676082 0 1.35216V12.5751C0 13.116 0.400168 13.6569 0.933725 13.9273C1.06711 13.9273 1.2005 14.0625 1.33389 14.0625H4.9354C5.46896 14.0625 6.00252 13.9273 6.53608 13.7921C7.20302 13.5216 7.60319 13.2512 8.00336 12.8456C8.40353 12.4399 8.6703 12.0343 8.93708 11.4934C9.20386 10.9525 9.20386 10.4117 9.20386 9.87079C9.20386 9.32993 9.07047 8.78906 8.93708 8.38341C8.80369 7.84255 8.53692 7.4369 8.13675 7.03125ZM6.2693 10.4117C6.2693 10.5469 6.13591 10.6821 6.00252 10.8173C5.86913 10.9525 5.73574 11.0877 5.60235 11.0877C5.46896 11.223 5.20218 11.223 5.06879 11.223H2.80118V8.38341H5.06879C5.20218 8.38341 5.46896 8.38341 5.60235 8.51863C5.73574 8.65385 5.86913 8.65385 6.00252 8.78906C6.13591 8.92428 6.2693 9.0595 6.2693 9.19471C6.40269 9.46515 6.40269 9.60036 6.40269 9.87079C6.40269 10.006 6.40269 10.2764 6.2693 10.4117ZM6.2693 4.73257C6.2693 5.00301 6.13591 5.13822 6.00252 5.27344C5.86913 5.40865 5.73574 5.40865 5.60235 5.54387C5.46896 5.54387 5.20218 5.67909 5.06879 5.67909H2.80118V2.83954H5.06879C5.20218 2.83954 5.46896 2.83954 5.60235 2.97476C5.73574 3.10998 5.86913 3.10998 6.00252 3.24519C6.13591 3.38041 6.2693 3.51563 6.2693 3.65084C6.40269 3.78606 6.40269 4.05649 6.40269 4.19171C6.40269 4.46214 6.40269 4.59736 6.2693 4.73257Z"
        fill="white"
      />
      <path
        d="M49.4876 1.35216C49.4876 0.540865 48.8207 0 48.1537 0C47.3534 0 46.8198 0.676082 46.8198 1.35216C46.8198 1.35216 46.8198 1.35216 46.8198 12.7103C46.8198 13.5216 47.4868 14.0625 48.1537 14.0625C48.9541 14.0625 49.4876 13.3864 49.4876 12.7103V1.35216Z"
        fill="white"
      />
      <path
        d="M64.0271 12.7103V1.35216C64.0271 0.540865 63.3602 0 62.6932 0C61.8929 0 61.3593 0.676082 61.3593 1.35216V7.70733L57.3576 0.676082C57.2243 0.405649 56.9575 0.270433 56.6907 0.135216C56.5573 0.135216 56.5573 0.135216 56.4239 0.135216C56.4239 0.135216 56.4239 0.135216 56.2905 0.135216H56.1571C56.1571 0.135216 56.1571 0.135216 56.0237 0.135216H55.8904H55.757C55.2234 0.270433 54.8232 0.811298 54.8232 1.35216V12.7103C54.8232 13.5216 55.4902 14.0625 56.1571 14.0625C56.9575 14.0625 57.491 13.5216 57.491 12.7103V6.49039L61.3593 13.3864C61.6261 13.7921 62.0263 14.0625 62.4264 14.0625H62.5598C63.3602 14.0625 64.0271 13.5216 64.0271 12.7103Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1105_6632">
        <rect width="64" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
