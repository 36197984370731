<template>
  <button
    :style="{
      '--var-bg-hover': bgHover,
      '--var-bg-selected': bgSelected,
      '--var-bg': sidebarColor,
    }"
    @click.stop="$emit('navigate:to', item)"
    :class="[
      item.current
        ? 'custom-class--current bg-sky-600 text-white'
        : 'custom-class bg-sky-500 text-white hover:bg-sky-400 hover:text-slate-50  focus:bg-sky-400  focus:outline-none',
      'group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-left text-sm font-normal',
    ]"
  >
    <component
      :is="item.icon"
      :class="[{ 'text-white': item.current }, 'mr-3 h-6 w-6 flex-shrink-0']"
      aria-hidden="true"
    />
    {{ item.name }}
  </button>
</template>

<script setup>
import tinycolor from "tinycolor2";
import { computed, inject } from "vue";

defineEmits(["navigate:to"]);
defineProps(["item"]);

const sidebarColor = inject("sidebarColor");

const bgHover = computed(() => {
  const tcolor = tinycolor(sidebarColor.value);
  return tcolor.lighten(10);
});

const bgSelected = computed(() => {
  const tcolor = tinycolor(sidebarColor.value);
  return tcolor.darken(10);
});
</script>

<style scoped>
:root {
  --var-bg-hover: transparent;
  --var-bg-selected: transparent;
  --var-bg: transparent;
}

.custom-class--current {
  background-color: var(--var-bg-selected);
}

.custom-class {
  background-color: var(--var-bg);
}

.custom-class:hover {
  background-color: var(--var-bg-hover);
}

.custom-class:focus {
  background-color: var(--var-bg-hover);
}
</style>
