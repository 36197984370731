import { createApp } from "vue";
import type { App, InjectionKey } from "vue";
import Alert from "./AlertBox.vue";

export interface Options {
  autoCloseInMs: number;
}
export type ErrorFunc = (errors: string[], options?: Options) => void;
export type AlertFunc = (message: string, options?: Options) => void;
export interface ComponentInstance {
  info: AlertFunc;
  error: ErrorFunc;
  warning: AlertFunc;
}
export const alertKey = Symbol() as InjectionKey<ComponentInstance>;

export default {
  install: (app: App) => {
    const alertInstance = createApp(Alert);
    const div = document.createElement("div");
    document.body.appendChild(div);
    const mountInstance: ComponentInstance = alertInstance.mount(
      div
    ) as unknown as ComponentInstance;

    const info = function (message: string, options?: Options) {
      mountInstance.info(message, options);
    };
    const error = function (errors: string[], options?: Options) {
      mountInstance.error(errors, options);
    };

    const warning = function (message: string, options?: Options) {
      mountInstance.warning(message, options);
    };

    app.config.globalProperties.$alert = { info, error, warning };
    app.provide(alertKey, { info, error, warning });
    app.provide("alert", { info, error, warning });
  },
};
