<template>
  <div v-for="subItem in item.children" :key="subItem.name">
    <DisclosureButton
      v-if="subItem?.permissions ? passport(subItem?.permissions) : true"
      as="a"
      :style="{
        '--var-bg-hover': bgHover,
        '--var-bg-selected': bgSelected,
        '--var-bg': sidebarColor,
      }"
      :class="[
        subItem?.current ? 'outline-none ring-2 ring-white' : '',
        'custom-class group relative flex w-full cursor-pointer items-center rounded-md py-2 pl-2 pr-2 text-sm font-normal text-white hover:bg-sky-400 hover:text-slate-50',
      ]"
      @click.stop="$emit('navigate:to', { item, subItem })"
    >
      <span class="ml-8"> {{ subItem.name }}</span>
    </DisclosureButton>
  </div>
</template>

<script setup>
import tinycolor from "tinycolor2";
import { DisclosureButton } from "@headlessui/vue";
import { computed, inject } from "vue";

defineEmits(["navigate:to"]);
defineProps(["item"]);

const passport = inject("passport");

const sidebarColor = inject("sidebarColor");

const bgHover = computed(() => {
  const tcolor = tinycolor(sidebarColor.value);
  return tcolor.lighten(10);
});

const bgSelected = computed(() => {
  const tcolor = tinycolor(sidebarColor.value);
  return tcolor.darken(10);
});
</script>

<style scoped>
:root {
  --var-bg-hover: transparent;
  --var-bg-selected: transparent;
  --var-bg: transparent;
}

.custom-class--current {
  background-color: var(--var-bg-selected);
}

.custom-class {
  background-color: var(--var-bg);
}

.custom-class:hover {
  background-color: var(--var-bg-hover);
}

.custom-class:focus {
  background-color: var(--var-bg-hover);
}
</style>
