export default {
  login: {
    title_welcome: "Welcome to Brain, by GOI.",
    subtitle_welcome_shorth:
      "You are within a step to start using Brain, the software for managing deliveries, by GOI Group.",
    subtitle_welcome_large:
      "You are within a step to start using Brain, the software for managing deliveries, by GOI Group. In case you forgot your password, you will find an option to create a new one, as we do not record our user’s password according to LGPD",
    link_remember_password: "I forgot my password",
    button_log_me: "Log me in",
    label_input_email: "Email",
    placeholder_input_email: "Ej: dolores{'@'}letsgoi.com",
    label_input_password: "Password",
    login_from_token: "Login from token...",
  },
};
