export default {
  title: "Additional Info",
  edit_info: "Edit Info",
  edit: "Edit",
  rating: "Delivery Rating:",
  last_update: "Last Update:",
  pick_up: "Picked up at:",
  shipped_at: "Shipped at:",
  closed_at: "Closed at:",
  cash: "Cash on Delivery:",
  retail_price: "Retail Price:",
  estimated_time: "Estimated Time:",
  warehouse: "Warehouse locators",
  label_cash: "Cash on Delivery",
  label_retail_price: "Retail Price",
  label_estimated_time: "Estimated Time",
  not_described: "Unspecified",
  not_especified: "Unspecified",
};
