export default {
  title: "Logs",
  tab_events: "Events",
  tab_audits: "Audits",
  label_description: "Description",
  label_value: "Value",
  label_changed: "Changed: ",
  label_now: "Now",
  label_before: "Before",
  title_placeholder_empty: "No Event Logs yet recorded",
  subtitle_placeholder_empty: "As soon they come will be shown",
  title_audits_placeholder_empty: "No Audits Logs yet recorded",
  subtitle_audits_placeholder_empty: "As soon they come will be shown",
};
