export default {
  creation: {
    title_create: "Create Incidence",
    title_section_tags: "Tags",
    title_section_date: "When did the incidence happen?",
    title_section_details: "Incidence details",
    title_section_priority: "Priority",
    title_section_cost: "Extra cost to be applied?",
    title_section_description: "Describe the incidence briefly",
    label_input_date: "Date of the incidence",
    label_input_status_progress: "Set as In Progress",
    label_input_reason_incidence: "Reason of incidence",
    label_input_liable: "Incidence Attributable to",
    label_input_assigned_to: "Assigned to",
    label_input_description: "Description",
    label_input_reason: "Reason of incidence",
    placeholder_reason_incidence: "Choose the reason from the list",
    placeholder_liable: "Choose liable from the list",
    placeholder_assigned_to: "Choose a team mate from the list",
    placeholder_tracked_at: "Select tracked at",
    placeholder_cost: "How much?",
    placeholder_priority: "Select Priority",
    placeholder_description: "Try to describe the problem as brief as you can",
    label_error_priority: "This info is mandatory",
    label_cost_no: "No",
    label_cost_yes: "Yes",
    creation_incidence: "Creating incidence",
    btn_cancel: "Cancel",
    btn_save: "Save",
  },
  list: {
    title: "Incidences",
    placeholder_input_search: "Search",
    "button_download-incidences": "Download Incidences",
    "button_download-all": "Download Everything",
    button_downloading: "Downloading File...",
    "button_bulk-close": "Set all as resolved",
    "bulk-closed-message": "All incidences closed",
    action_bar_filters: "Filters",
    action_bar_columns: "Columns",
    "total-count": "Showing {totalcount} Results",
    table_headers: {
      id: "ID",
      reference: "Reference",
      order: "Order Reference",
      operation: "Operation",
      type: "Type",
      priority: "Priority",
      assigned: "User Assigned",
      created_at: "Created At",
      closed_at: "Closed At",
      tracked_at: "Tracked At",
      cost: "Cost",
      status: "Status",
      description: "Description",
      liables: "Attributable to",
      tags: "Tags",
      source: "Created By",
    },
    "button_download-all-columns": "Download All Columns",
    "button_download-all-columns-text": "All columns will be downloaded on CSV",
    "button_download-current-columns": "Download Only Current Columns set up",
    "button_download-current-columns-text":
      "Apply only to columns currently shown",
    "button_download-especific-columns": "Choose Especific Columns to Download",
    "button_download-especific-columns-text":
      "Choose which columns to apply from list",
    "button_download-max-limit":
      "Only available for results under {count} incidences",
    views: "Views",
    "download-orders-email_success":
      "Processing request. On {email} will receive the link",
  },
  detail: {
    title_section_incidence_information: "Incidence Information",
    subtitle_section_incidence_information:
      "You can edit info through this form if necessary.",
    label_input_order_reference: "Order reference",
    label_input_reason: "Reason of the incidence",
    label_input_attributable_to: "Incidence Attributable to",
    label_input_assigned_to: "Assigned to",
    label_input_cost: "Cost",
    label_input_description: "Description",
    title_section_customer_information: "Customer Information",
    subtitle_section_customer_information:
      "Read-Only information. Visit the order detail to edit info.",
    label_input_customer_name: "Customer's Name",
    label_input_phone: "Phone",
    label_input_email: "Email",
    btn_cancel: "Cancel",
    btn_save: "Save",
    "status-opened": "Opened",
    "status-inProgress": "In Progress",
    "status-closed": "Closed",
    components: {
      card_related_incidences: {
        title: "related incidences",
        "no-related": "No related incidences",
        view_more: "View More",
        view_less: "View Less",
      },
      card_status_incidence: {
        "status_incidence-title": "Incidence Status",
        "status_incidence-edit": "Edit",
        "status_incidence-current-status": "Current Status",
        "status_incidence-priority": "Priority",
        "status_incidence-date": "Date of incidence",
        "status_incidence-update": "Last update",
        "status_incidence-created": "Created At",
        "status_incidence-closed": "Closed At",
        "status_incidence-source": "Created by",
        "editing_status-title": "Edit status",
        "editing_status-save": "Save",
        "editing_status-now": "Set now",
        "editing_status-choose": "Choose a date",
        "status_date-title": "Choose status date",
        "status_date-save": "Save",
        "status_date-time": "Specify time",
        "status_reason-title": "Specify Reason",
        "status_reason-save": "Save",
        "status_reason-label": "Reason to close incidence",
      },
    },
  },
};
