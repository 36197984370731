<template>
  <div
    class="group relative"
    ref="triggerElement"
    @mouseenter="showTooltip"
    @mouseleave="hideTooltip"
  >
    <slot></slot>
    <Teleport to="body">
      <div
        v-show="isVisible"
        :style="[
          tooltipStyles,
          {
            backgroundColor: bgTooltip,
          },
        ]"
        class="absolute z-50 mt-3 rounded-lg bg-sky-600 p-2 text-sm leading-tight text-white"
      >
        {{ tip }}
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import tinycolor from "tinycolor2";
import {
  ref,
  reactive,
  onMounted,
  watch,
  nextTick,
  inject,
  computed,
} from "vue";

defineProps(["tip"]);
const triggerElement = ref(null);
const isVisible = ref(false);

const tooltipStyles = reactive({
  position: "fixed",
  top: "0px",
  left: "0px",
});

const showTooltip = () => {
  isVisible.value = true;
};

const hideTooltip = () => {
  isVisible.value = false;
};

onMounted(() => {
  watch([isVisible], async () => {
    await nextTick();
    if (isVisible.value && triggerElement.value) {
      const triggerRect = triggerElement.value.getBoundingClientRect();
      tooltipStyles.top = `${window.scrollY + triggerRect.bottom}px`;
      tooltipStyles.left = `${window.scrollX + triggerRect.left}px`;
    }
  });
});

const sidebarColor = inject("sidebarColor");

const bgTooltip = computed(() => {
  const tcolor = tinycolor(sidebarColor.value);
  return tcolor.darken(10);
});
</script>
