import passport from "./passport";
import type { InjectionKey } from "vue";
import { getUserPermissions, getUserRoles, getUserScopes } from "./logic";

export type Action = "hideMe" | "disableMe";
export type UserDataAdapter = () => Array<string>;
export type CheckCredentialClosure = (permissions: string) => boolean;
export interface Options {
  getUserPermissions: UserDataAdapter;
  getUserRoles?: UserDataAdapter;
  getUserScopes?: UserDataAdapter;
}
export interface Authentication {
  permissions: string;
  inCaseNotAuth: Action;
}

export const passportKey = Symbol() as InjectionKey<CheckCredentialClosure>;
export const passportRoleKey = Symbol() as InjectionKey<CheckCredentialClosure>;
export const passportScopeKey =
  Symbol() as InjectionKey<CheckCredentialClosure>;

export const IN_CASE_NOT_AUTH: Readonly<Array<Action>> = [
  "hideMe",
  "disableMe",
];
export { getUserPermissions, getUserRoles, getUserScopes };
export default passport;
