export default {
  title: "Logistic Details",
  customer: "Customer",
  services: "Serv.:",
  content: "Content:",
  articles: "art.",
  packages: "pkgs.",
  specs: "Specs.:",
  delivery: "Delivery",
  vol: "m³",
  pickup: "Pickup",
  edit_cargo: "Edit Cargo Specifications",
  weight: "Weight",
  volume: "Volume",
  edit_customer: "Edit Customer",
  name: "Name",
  last_name: "Last Name",
  email: "Email",
  phone: "Phone",
  placeholder_name: "Insert Name",
  placeholder_email: "Insert Email",
  placeholder_last_name: "Insert Last Name",
  placeholder_phone: "+34 678 894 123",
  placeholder_volume: "Insert Volume",
  placeholder_weight: "Insert Weight",
  edit_services: "Edit Services",
  edit_content: "Edit Content",
  label_packages: "Packages",
  unit_packages: "Uds",
  edit_delivery: "Edit Delivery Address",
  edit_pickup: "Edit Pickup Address",
  label_address: "Customer Address",
  label_zip: "Zip Code",
  label_province: "Province",
  label_Additiona_info: "Customer Address - Aditional info",
  label_city: "City",
  label_country: "Country",
  label_latitude: "Latitude",
  label_longitude: "Longitude",
  placeholder_latitude: "Insert Latitude",
  placeholder_longitude: "Insert Longitude",
  placeholder_additional_address: "Insert additional info",
  placeholder_city: "Madrid",
  placeholder_zip: "28012",
  placeholder_packages: "Insert Packages",
  placeholder_address_title: "No Delivery Address",
  placeholder_address_subtitle: "Edit delivery address to start",
  placeholder_pick_address_title: "No Pick Up Address",
  placeholder_pick_address_subtitle: "Edit pick up address to start",
};
