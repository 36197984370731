import { defineStore, type PiniaPluginContext } from "pinia";
import { reqGetUserProfile } from "@/common/helpers/api";
import isEmpty from "lodash-es/isEmpty";
import * as jose from "jose";
import { EMPTY_STRING } from "@/common/helpers/constants";
import { session } from "@letsgoi/areq";

export const useAuthStore = defineStore("authStore", {
  state: (): Auth.State => ({
    profile: {} as Auth.Profile,
  }),
  getters: {
    gettProfile: (state) => state.profile,
  },
  actions: {
    async accGetProfile() {
      const token = session.getToken();
      const claims = jose.decodeJwt(token?.access_token ?? EMPTY_STRING);
      this.profile = await reqGetUserProfile();
      this.profile.scopes = (claims.scopes as Auth.Scope[]) ?? [];
    },
  },
});

export type AuthStore = ReturnType<typeof useAuthStore>;
export const updateTokenAndProfilreFromLocalStore = ({
  store,
}: PiniaPluginContext) => {
  if (store.$id === "authStore") {
    if (isEmpty(store.$state?.profile)) {
      store.$state.profile = JSON.parse(
        globalThis.localStorage.getItem("profile") ?? "{}"
      );
    }
  }
};
