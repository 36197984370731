import areq from "@/common/helpers/aReq";
import AreqTools from "@letsgoi/areq";
import { EMPTY_STRING } from "@/common/helpers/constants";
import { ARTICLES } from "./paths";

const {
  setBody,
  GET,
  POST,
  PATCH,
  DELETE,
  jsonData,
  setPassport,
  setLetgoiHeaders,
} = AreqTools;

export function reqGetArticlesList(queryParams = EMPTY_STRING) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("list-articles"), []))
    .then(GET(`${ARTICLES}${queryParams}`))
    .then(jsonData<Api<Orders.Article[]> | Orders.Article[]>())
    .catch((error) => Promise.reject(error));
}

export function reqGetArticlesCount(queryParams = EMPTY_STRING) {
  return areq()
    .then(setLetgoiHeaders)
    .then(GET(`${ARTICLES}/count${queryParams}`))
    .then(jsonData<Promise<PageCount>>())
    .catch((error) => Promise.reject(error));
}

export function reqPostCreateArticleOnBulk(payLoadfields: {
  client_id: string;
  articles: Orders.ArticlePayloadPost[];
}) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("create-articles"), []))
    .then(setBody(payLoadfields))
    .then(POST(`${ARTICLES}/bulk`))
    .then(jsonData())
    .catch((error) => Promise.reject(error));
}

export function reqPostCreateArticle({
  client_id,
  article,
}: {
  client_id: string;
  article: Orders.ArticlePayloadPost;
}) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("create-articles"), {}))
    .then(setBody({ client_id, ...article }))
    .then(POST(`${ARTICLES}`))
    .catch((error) => Promise.reject(error));
}

export function reqPatchUpdateArticle(
  id: string,
  article: Orders.ArticlePayloadPatch
) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("update-articles"), {}))
    .then(setBody(article))
    .then(PATCH(`${ARTICLES}/${id}`))
    .catch((error) => Promise.reject(error));
}
export function reqGetArticle(id: string) {
  return areq()
    .then(setLetgoiHeaders)
    .then(GET(`${ARTICLES}/${id}`))
    .then(jsonData<Promise<Orders.Article>>())
    .catch((error) => Promise.reject(error));
}

export function reqDeleteArticle(id: string) {
  return areq()
    .then(setLetgoiHeaders)
    .then(setPassport(globalThis.passport("delete-articles"), {}))
    .then(DELETE(`${ARTICLES}/${id}`))
    .catch((error) => Promise.reject(error));
}
