import { EMPTY_STRING } from "@/common/helpers/constants";

export default {
  install: (app) => {
    const send = (
      data: { UUID: string } = { UUID: EMPTY_STRING },
      origin = window.location.origin
    ) => {
      const params = !data.UUID
        ? { ...data, UUID: import.meta.env.VITE_APP_UUID }
        : data;
      window.top?.postMessage(params, origin);
    };

    const sendToVeins = (
      data: { UUID: string } = { UUID: EMPTY_STRING },
      origin = import.meta.env.VITE_APP_ROUTES_HOST
    ) => {
      const params = !data.UUID
        ? { ...data, UUID: import.meta.env.VITE_APP_VEINS_UUID }
        : data;
      const iframe = document.querySelector(
        "#veins-iframe"
      ) as HTMLIFrameElement;
      if (iframe) iframe?.contentWindow?.postMessage(params, origin);
    };

    const listen = (callBack: (ev: MessageEvent) => void) =>
      window.addEventListener(
        "message",
        (ev: MessageEvent) => {
          if (ev.data.UUID === import.meta.env.VITE_APP_UUID) {
            callBack(ev);
          }
        },
        false
      );

    const remove = (callBack: () => void) => {
      window.removeEventListener("message", callBack, false);
    };

    const methods = { send, sendToVeins, listen, remove };
    app.config.globalProperties.$eventbus = methods;
    globalThis.$eventbus = methods;
    app.provide("eventbus", methods);
  },
};
