import { createApp } from "vue";
import i18n from "./common/helpers/i18n";
import router from "./router";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import isEmpty from "lodash-es/isEmpty";
import Passport, {
  getUserPermissions,
  getUserRoles,
  getUserScopes,
} from "@/common/plugins/passport";
import profileWatcher from "@/common/plugins/profileWatcher";
import App from "./App.vue";
import Alert from "@/common/plugins/alert";
import EventBus from "@/common/plugins/eventBus";
import posthog, { type CapturedNetworkRequest } from "posthog-js";
import Logger from "@/common/plugins/Logger";
import { updateTokenAndProfilreFromLocalStore } from "@/modules/auth/store";

// styles
import "./styles/main.scss";
import "./twstyles/styles.css";
import { EMPTY_STRING } from "@/common/helpers/constants";

const pinia = createPinia();
const app = createApp(App);
const release = VITE_APP_VERSION;
if (import.meta.env.VITE_APP_TRACKING_ENV === "production") {
  Sentry.init({
    app,
    release,
    dsn: import.meta.env.VITE_APP_SENTRY_URL,
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["brain.letsgoi.com", "localhost"],
    beforeSend(event: Sentry.Event) {
      if (event.exception && event.exception.values) {
        for (const value of event.exception.values) {
          if (
            /Failed to fetch dynamically imported module/.test(
              value.value || ""
            ) ||
            /vite:preloadError/.test(value.value || "")
          ) {
            return null;
          }
        }
      }
      const profile: Auth.Profile = JSON.parse(
        localStorage.getItem("profile") ?? "{}"
      );
      if (!isEmpty(profile)) {
        event.user = {
          ...event.user,
          email: profile?.email ?? EMPTY_STRING,
        };
      }
      return event;
    },
  });
}

if (
  import.meta.env.VITE_APP_TRACKING_ENV === "beta" ||
  import.meta.env.VITE_APP_TRACKING_ENV === "staging" ||
  import.meta.env.VITE_APP_TRACKING_ENV === "production"
) {
  posthog.init(import.meta.env.VITE_APP_POSTHOG_TOKEN, {
    api_host: import.meta.env.VITE_APP_POSTHOG_HOST,
    person_profiles: "identified_only",
    session_recording: {
      recordCrossOriginIframes: true,
      maskCapturedNetworkRequestFn: (request: CapturedNetworkRequest) => {
        return request;
      },
    },
  });
}

pinia.use(updateTokenAndProfilreFromLocalStore);
app.use(pinia);
app.use(router);
app.use(i18n);
app.use(profileWatcher);
app.use(Passport, { getUserPermissions, getUserRoles, getUserScopes });
app.use(Alert);
app.use(EventBus);
app.use(Logger);

app.mount("#app");

app.config.errorHandler = (err, vm, info) => {
  console.error("Globlal vue file error captured:", err, info);
};
