import isEqual from "lodash-es/isEqual";
import * as jose from "jose";
import { EMPTY_STRING } from "@/common/helpers/constants";
import { reqGetUserProfile } from "@/common/helpers/api";
import { session } from "@letsgoi/areq";

export default {
  install: async () => {
    const token = session.getToken();
    if (token?.access_token) {
      try {
        // console.log(`Requesting for new permissions token: ${token.slice(0, 8)}...`);
        const newProfile: Auth.Profile = await reqGetUserProfile();
        const profile: Auth.Profile = JSON.parse(
          localStorage.getItem("profile") ?? "{}"
        );

        const claims = jose.decodeJwt(token?.access_token ?? EMPTY_STRING);
        localStorage.setItem(
          "profile",
          JSON.stringify({ ...newProfile, scopes: claims.scopes })
        );
        // console.log(`Old: ${oldPermissions.length} New: ${permissions.length}`);
        if (
          !isEqual(profile.permissions, newProfile.permissions) ||
          !isEqual(profile.roles, newProfile.roles)
        ) {
          // console.log('Permissions are diferent, updating to newOnes');

          localStorage.setItem(
            "profile",
            JSON.stringify({
              ...profile,
              permissions: newProfile.permissions,
              roles: newProfile.roles,
              scopes: claims.scopes,
            })
          );
          window.location.reload();
        }
      } catch (error) {
        console.error(error);
      }
    }
  },
};
