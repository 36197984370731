export default {
  list: {
    title: "Dedicated Services",
    "button_new-vehicle": "Vehicles",
    "button_new-personnel": "Personnel",
    "section_download-orders": "Download Dedicated Services",
    "section_download-orders-sub": "Filtering to activate",
    "button_download-max-limit": "Max. {count}",
    "button_download-incorrect-limit": "Used incorrect limit",
    table_headers: {
      id: "ID",
      service: "Service",
      client: "Client",
      status: "Status",
      schedule: "Schedule",
      creation: "Creation",
      responsible: "Responsible",
      base: "Base address",
      ending: "Ending Address",
      assignment: "Assignment",
      reference: "Reference",
      type: "Type",
      category: "Category",
      subtype: "Subtype",
      operation: "Operation",
      time_window_from: "Time Window From",
      time_window_to: "Time Window To",
      created_at: "Created At",
      responsible_firstname: "Responsible Firstname",
      responsible_lastname: "Responsible Lastname",
      responsible_email: "Responsible Email",
      responsible_phone: "Responsible Phone",
      base_address: "Base Address",
      base_additional_address: "Base Additional Address",
      base_city: "Base City",
      base_zip: "Base Zip",
      base_province: "Base Province",
      ending_address: "Ending Address",
      ending_additional_address: "Ending Additional Address",
      ending_city: "Ending City",
      ending_zip: "Ending Zip",
      ending_province: "Ending Province",
      provider: "Provider",
      team: "Team",
      plate: "Plate",
      notes: "Notes",
      tags: "Tags",
    },
  },
  detail: {
    header: {
      duplicate_btn: "duplicate",
    },
    form: {
      vehicles: "Dedicated Service for",
      dates: "Schedule dates",
      operation: "Service Operated by",
      client: "Client Contracting",
    },
    components: {
      demander_contact: {
        title: "demander contact",
        edit: "Edit",
        save: "Save",
        saved: "Saved",
        first_name: "Responsible Firstname",
        last_name: "Responsible Lastname",
        number: "Contact Number",
        email: "Email",
      },
      service_hired: {
        title: "service hired",
        edit: "Edit",
        save: "Save",
        saved: "Saved",
        category: "Category",
        type: "Type",
        subtypes: "Subtypes",
        placeholder_category: "Select category",
        placeholder_type: "Select type",
        placeholder_subtype: "Select subtype",
      },
      service_address: {
        base_address_title: "BASE ADDRESS",
        ending_address_title: "ENDING ADDRESS",
        service_address_title: "SERVICE ADDRESS",
        base_address: "Base",
        label_base_address: "Base",
        ending_address: "Ending",
        label_ending_address: "Ending",
        additional: "Additional Address",
        edit: "Edit",
        save: "Save",
        saved: "Saved",
        zip: "Zip code",
        city: "City",
        country: "Country",
      },
      service_demander: {
        title: "service demander",
        edit: "Edit",
        save: "Save",
        saved: "Saved",
        label_client: "Client",
        label_operation: "Operation",
        placeholder_operation: "Operation",
      },
      service_operator: {
        title: "service operator",
        edit: "Edit",
        save: "Save",
        saved: "Saved",
        label_provider: "Provider",
        placeholder_provider: "Provider",
        label_team: "Team",
        placeholder_team: "Team",
        label_plate: "Plate",
        label_phone: "Team telephone number",
      },
      service_scheduled: {
        title: "service schedule",
        edit: "Edit",
        save: "Save",
        saved: "Saved",
        next: "Next",
        label_date_service: "Date of service",
        label_hours: "Hrs hired",
        label_time_from: "Time from",
        label_time_to: "Time to",
        label_creation_date: "creation Date",
        reason_incidence: "Specify a reason",
      },
      service_extra: {
        title: "service supplements",
        edit: "Edit",
        save: "Save",
        new_extra_btn: "Add supplement",
        placeholder: "Add a new supplement",
        view_more: "View More",
        view_less: "View Less",
      },
      tags: {
        title: "Tags",
        create: "Create tag:",
        add: "Add tag",
        save: "Save",
      },
      service_notes: {
        title: "notes",
        edit: "Edit",
        save: "Save",
        saved: "Saved",
        add: "Add note",
        edit_title: "Write a note",
        view_more: "View More",
        view_less: "View Less",
      },
    },
  },
  creation: {
    title_section_warehouse: "Dedicated Service for warehousing",
    title_section_extras: "Service Supplements",
    title_section_dates: "Schedule dates",
    title_section_operated: "Service Operated by",
    title_section_client: "Client Contracting",
    title_section_responsible: "Responsible details",
    title_section_base_address: "Base Address",
    title_section_ending_address: "Ending Address",
    title_section_tags: "Tags",
    title_section_notes: "Notes",
    supplements: "Service Supplements",
    responsible: "Responsible details",
    base: "Base Address",
    ending: "Ending Address",
    delete_ending: "Delete Ending Address",
    add_ending: "Add Ending Address",
    schedule_starting: "Starting",
    schedule_ending: "Ending",
    schedule_contract: "The contract is for a time period",
    button_publish: "Publish",
    label_input_client: "Client",
    label_input_operation: "Operation",
    label_input_provider: "Provider (Optional)",
    label_input_teams: "Teams (Optional)",
    label_input_type_service: "Type of service",
    label_input_subtype_service: "Subtype",
    label_input_name: "Name",
    label_input_last_name: "Last Name",
    label_input_base_address: "Base Address",
    label_input_additional_address: "Customer Address - additional info",
    label_input_city: "City",
    label_input_zip: "Zip",
    label_input_province: "Province",
    label_input_country: "Country",
    label_input_ending_address: "Ending Address",
    label_input_note: "Add a note",
    label_input_contract: "The contract is for a time period",
    label_error_frequency: "Dates and frequency are required",
    placeholder_input_client: "Select client",
    placeholder_input_operation: "Select operation",
    placeholder_input_provider: "Select provider",
    placeholder_input_team: "Select team",
    placeholder_input_type_service: "Select type",
    placeholder_input_subtype_service: "Select subtype",
    placeholder_input_name: "Insert Name",
    placeholder_input_last_name: "Insert Last name",
    placeholder_input_address: "Insert Customer Address, nº...",
    placeholder_input_additional_address: "4th floor",
    placeholder_input_city: "Madrid",
    placeholder_input_zip: "28012",
    placeholder_input_province: "Insert Province",
    placeholder_input_country: "Select Country",
    creation_ds: "Creating dedicated services",
    duplication_ds: "Loading dedicated services",
    btn_cancel: "Cancel",
    btn_save: "Save",
  },
  calendar: {
    loading: "Loading Services",
    view_more: "View more",
    view_detail: "View services details",
  },
};
