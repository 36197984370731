export default {
  "module-name": "Alerts",
  reference: "Reference",
  title: "Title",
  description: "Description",
  status: "Status",
  priority: "Priority",
  open_date: "Open Date",
  closing_date: "Closing Date",
  related_to: "Related To",
  entity_id: "Entity Id",
  type: "Type",
  id: "Id",
  department: "Departments",
  columns: "Columns",
  filters: "Filters",
  alerts: "Alerts",
  open: "Open",
  open_today: "Open today",
  more_than_three_days_opened: "More than 3 days opened",
  urgents: "Urgents!",
  high_priority: "High priority",
  medium_priority: "Medium priority",
  view_alert_relation: "View alert relation",
  without_alert_relation: "Without alert relation",
  opened: "Opened",
  days_opened: "{num} days opened",
  closed: "Closed",
  alert_details: "Alert Details",
  alert_logs: "Alert Logs",
  close_alert: "Close Alert",
  no_date_yet: "No date yet",
  less_than_one_day: "Less than 1 day opened",
  zone: "Zone",
  not_especified: "Unspecified",
  tags: "Tags",
  "create-alert": "Create Alert",
};
