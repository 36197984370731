import { ref, onMounted } from "vue";

export function useOperatingSystem() {
  const isMacOS = ref(false);

  onMounted(() => {
    const userAgent = navigator.userAgent;
    isMacOS.value = /Macintosh|MacIntel|MacPPC|Mac68K|MacOS/.test(userAgent);
  });

  return { isMacOS };
}
