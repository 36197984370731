<template>
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 286.1 163.7"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        fill="white"
        d="M161.8,77.5v-0.4c0-11.4-7.3-21.3-19.1-21.3c-12.5,0-18.9,9.7-18.9,20.8V77c0,11.2,7.1,21.3,19.1,21.3
		C155.4,98.3,161.8,88.6,161.8,77.5z"
      />
      <path
        class="st0"
        fill="white"
        d="M233.4,132.3c-2.6-1.1-5.4-0.6-7.5,1.5c-1.9,1.7-2.6,4.9-1.5,7.5c1.3,2.6,3.9,4.3,6.7,4.3c4.1,0,7.1-3,6.6-6.6
		C237.7,136,236,133.4,233.4,132.3z"
      />
      <path
        class="st0"
        fill="white"
        d="M54.2,56.2c-12.3,0-19.8,9.4-19.8,20.6v0.4c0,11.1,8.2,21,20,21c12.3,0,19.8-9.4,19.8-20.6v-0.4
		C74.2,66.1,66,56.2,54.2,56.2z"
      />
      <path
        class="st0"
        fill="white"
        d="M272.6,96.9l-7.9-18.5c-3-8.4-10.7-14.4-19.8-14.8h-19.3c-0.4,0-0.6,0-0.9,0V51.4c0-5.4-1.3-10.1-3.7-13.7
		c2.4-3.7,3.7-7.9,3.7-12.7c0-12.9-10.5-23.4-23.2-23.4c-12.9,0-23.2,10.5-23.2,23.4c0,4.7,1.3,9,3.9,12.7c-0.4,0.4-0.6,1.1-0.9,1.7
		c-9.9-9.9-23.4-15.2-38-15.2c-15,0-28.6,5.6-38.7,15.7c-0.4,0.4-0.4,0.4-0.9,0.9c-0.6-0.9-1.3-1.7-2.1-2.6
		c-4.5-4.5-10.1-6.9-16.5-6.9h-0.2c-1.3,0-2.6,0-3.9,0.2c-8.2-4.5-17.2-6.7-26.6-6.7c-0.2,0-0.4,0-0.4,0c-14.8,0-28.3,5.8-37.8,15.9
		c-9,9.7-13.7,22.8-13.7,37.2c0,11.8,3.2,22.3,8.8,31.1c-4.9,4.1-8.4,10.5-8.4,17.8c0,6.4,2.8,10.5,4.7,13.5l0.2,0.2l0.4,0.4
		l0.5,0.4c10.5,13.3,27.1,21,44.2,21c5.8,0,11.6-0.9,17.2-2.4c12.7-3.7,22.8-11.2,29.4-22.3c4.1-6.9,6.4-14.2,7.5-20.4
		c5.8,5.4,12.9,9.2,20.8,11.2v8.8c0,11,8.6,19.8,20,21c7.5,0.9,14.8-2.1,19.5-7.3h45.4c4.1,6.2,11,10.3,18.9,10.3
		c6.9,0,13.1-3,17.2-7.9h15.5c10.7,0,19.8-8.8,19.8-19.5v-19.5C283.5,106.8,279,99.9,272.6,96.9z M94.7,106.9v1.3
		c-1.1,15.2-7.8,33.9-28.6,39.7c-4.3,1.3-9,1.9-13.5,1.9c-15.2,0-27-7.7-33.7-16.1c-1.5-2.4-2.8-4.1-2.8-6.7c0-5.3,3.2-9.2,9-9.4
		c3,0,4.5,0.5,8.4,3.9s13.6,11.4,27.3,7.5c2.4-0.8,9.7-2.3,12.7-13.5c0-0.7,0.2-2.2,0.4-2.8c-0.7,0.2-1.1,0.4-1.1,0.4l-5.4,2.1
		c-4.8,1.5-9.5,2.1-14.2,2.1c-23.6,0-38-17.4-38-39.7c0-21.9,14.6-39.7,38.2-39.7c8.8-0.2,17.6,2.5,24.9,7.7
		c1.7-1.1,3.4-1.3,6.4-1.3c2.8,0,5.1,0.8,7.4,3.7c2,1.9,2.4,4.7,2.4,7.7L94.7,84c0,0.2,0,0.4,0,0.6v16.5
		C94.9,103,94.9,105,94.7,106.9z M201.7,14.6c5.6,0,10.1,4.5,10.1,10.1s-4.5,10.1-10.1,10.1s-10.1-4.5-10.1-10.1
		C191.6,19.1,196.1,14.6,201.7,14.6z M211.6,51.1v54.8c0,5.6-1.5,10.1-9.9,10.1s-9.9-4.5-9.9-10.1V51.1c0-5.6,1.7-10.1,9.9-10.1
		C209.9,41,211.6,45.5,211.6,51.1z M102.5,77.3v-0.4c0-22.1,16.8-39.9,40.6-39.9c23.6,0,40.4,17.6,40.4,39.7v0.4
		c0,22.1-16.8,39.9-40.6,39.9C119,117.1,102.5,99.5,102.5,77.3z M216.3,138.1l-56.5,0.2l-0.2,0.9c-1.3,4.1-5.8,6.9-10.5,6.4
		s-8.6-4.1-8.6-8.4c0-0.4,0.2-0.6,0.4-0.9c0.2-0.2,0.4-0.4,0.9-0.4s0.9,0.2,1.1,0.4c0.4,0.2,0.4,0.4,0.4,0.9c0,3.2,3,6,6.9,6
		s6.9-2.8,6.9-6c-0.3-0.8,0.2-1.5,1-1.5l58-0.2c0.4,0,0.6,0.2,1.1,0.4c0.4,0.2,0.4,0.4,0.4,0.9C217.6,137.4,217,138.1,216.3,138.1z
		 M231.1,148.5c-5.6,0-10.1-4.3-10.1-9.9c0-5.4,4.5-9.9,10.1-9.9s10.1,4.5,10.1,9.9C241.2,144,236.7,148.5,231.1,148.5z
		 M271.4,134.2c-0.5,3.3-3.5,6.5-7.4,6.5h-17.8c-0.6,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3h18c2.1,0,4.1-1.9,4.1-4.1v-19.3
		c0-2.1-1.7-4.1-3.9-4.1h-0.2h-1.3c-0.4,0-1.1-0.4-1.3-0.9l-11-25.8v-0.2c-0.6-2.6-3-4.3-5.6-4.5h-18.9c-0.6,0-1.3-0.6-1.3-1.3
		c0-0.6,0.6-1.3,1.3-1.3H245c3.9,0.2,7.3,2.8,8.4,6.7l10.7,24.9h0.4c3.9,0,6.9,3,6.9,7.1V134.2z"
      />
    </g>
  </svg>
</template>
<script setup></script>
