<template>
  <TransitionRoot appear :show="show" as="template">
    <Dialog @close="emit('dialog:close', $event)" class="relative z-30">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>
      <div class="fixed inset-0 z-10 mx-0.5 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center text-center group-[.top]:items-start group-[.top]:pt-40"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              :class="[
                'relative transform overflow-hidden rounded-lg text-left transition-all',
                bgTransparent ? 'bg-transparent' : 'bg-white',
                bgTransparent ? 'shadow-none' : 'shadow-xl',
              ]"
            >
              <slot />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  TransitionRoot,
  TransitionChild,
} from "@headlessui/vue";

const emit = defineEmits<{
  "dialog:close": [state: boolean];
}>();
defineProps<{ show: boolean; bgTransparent?: boolean }>();
</script>
