<template>
  <main
    :data-version="version"
    :data-release="release"
    :class="[
      'grid h-svh grid-rows-[min-content_1fr] md:grid-cols-[min-content_1fr] md:grid-rows-[initial]',
      { 'custom-scrollbars': !operatingSystem.isMacOS },
    ]"
  >
    <SidebarAppMenu v-if="route?.meta?.menu" />
    <RouterView />

    <transition
      enter-active-class="transform ease-out duration-300 transition"
      enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <NewVersionAvailable
        v-if="vitePreloadError"
        @app:updated="vitePreloadError = false"
      />
    </transition>
  </main>
</template>

<script setup lang="ts">
import { RouterView, useRoute } from "vue-router";
import NewVersionAvailable from "@/common/components/NewVersionAvailable.vue";
import SidebarAppMenu from "@/common/components/SidebarAppMenu";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useOperatingSystem } from "@/common/composables/useUserAgent";

const operatingSystem = useOperatingSystem();
const vitePreloadError = ref(false);
const version = VITE_APP_VERSION;
const release = VITE_APP_RELEASE;
const route = useRoute();

// Cuando se produce un deploy los cambios en las hash de los fichero estaticos
// vulven el sistema inestable. Este error salta en eso casos en los que el estatico apuntado
// ya no existe. Ej: pinchamos en filtros y no se abre nada.
// https://github.com/vitejs/vite/issues/11804
// https://vitejs.dev/guide/build#load-error-handling
onMounted(() => {
  window.addEventListener("vite:preloadError", handleVitePreLoadError);
  window.addEventListener("error", handleResourceError, true);
  window.addEventListener("error", handleError);
});

onBeforeUnmount(() => {
  window.removeEventListener("vite:preloadError", handleVitePreLoadError);
  window.removeEventListener("error", handleResourceError, true);
  window.removeEventListener("error", handleError);
});

function handleResourceError(event) {
  if (event.target.tagName === "LINK" && event.target.rel === "stylesheet") {
    console.error("Css file load error:", event.target.href);
  }
}

function handleError(event) {
  console.error("Error capturado en window:", event.message, event);
}

function handleVitePreLoadError(event: Event) {
  vitePreloadError.value = true;
  const customEvent = event as CustomEvent;
  if (customEvent.detail?.url.endsWith(".css")) {
    console.error("Css preload file error:", customEvent.detail.url);
  } else {
    console.error("Preload file error:", customEvent);
  }
  vitePreloadError.value = true;
}
</script>
