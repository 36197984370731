export default {
  title: "Billing Concepts",
  button_add_income: "Add Income Concept",
  button_add_expense: "Add Expense Concept",
  button_edit_total: "Edit Total Values",
  button_delete: "Delete",
  button_edit: "Edit",
  title_income_form: "Add new income concept",
  title_expense_form: "Add new expense concept",
  label_expense: "Expense",
  label_income: "Income",
  label_gross: "Gross Margin",
  label_inc: "Inc.",
  label_exp: "Exp.",
  button_options: "Options",
  label_description: "Description",
  placeholder_input: "Type a value",
  placeholder_textarea: "Describe in brief",
  title_placeholder_empty: "No Billing Concepts recorded",
  subtitle_placeholder_empty:
    "There’s still no billing concepts yet for this order.",
};
